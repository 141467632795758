<template>
  <div class="procurePayDetail">
    <el-form class="form" label-width="auto">
      <el-form-item>
        <h1 style="margin-right: 20px;">{{ data.procurePay.id ? '编辑' : '新增' }}采购付款信息</h1>
        <el-button-group>
          <el-button @click="router.go(-1)">返回</el-button>
          <el-button type="primary" @click="add()" v-if="data.procurePay.id == null">保存</el-button>
          <el-button type="primary" @click="payment()" v-if="data.procurePay.id != null && data.procurePay.reviewState == 'pass'">保存</el-button>
        </el-button-group>
      </el-form-item>
      <el-form-item label="审核状态" v-if="data.procurePay.id != null">
        <el-text v-if="data.procurePay.reviewState == 'none'">未审核</el-text>
        <el-text type="success" v-if="data.procurePay.reviewState == 'pass'">通过</el-text>
        <el-text type="danger" v-if="data.procurePay.reviewState == 'unpass'">不通过</el-text>
      </el-form-item>
      <el-form-item label="审核备注" v-if="data.procurePay.id != null && data.procurePay.reviewState != 'none'">
        <el-text>{{ data.procurePay.reviewNote }}</el-text>
      </el-form-item>
      <el-form-item label="供应商">
        <el-select v-model="data.procurePay.supplier" filterable :disabled="data.procurePay.id != null">
          <el-option v-for='supplierItem in show.supplierList' :key="supplierItem.id" :label="supplierItem.value" :value="supplierItem.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="申请日期">
        <el-date-picker style="width: 100%;" v-model="data.procurePay.applyDate" type="date" :disabled="data.procurePay.id != null" />
      </el-form-item>
      <el-form-item label="用途">
        <el-select v-model="data.procurePay.useto" filterable :disabled="data.procurePay.id != null">
          <el-option v-for='paymentUsetoItem in show.paymentUsetoList' :key="paymentUsetoItem.id" :label="paymentUsetoItem.value" :value="paymentUsetoItem.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="金额">
        <el-input-number style="width: 100%;" :controls="false" :precision="2" v-model="data.procurePay.amount" :disabled="data.procurePay.id != null" />
      </el-form-item>
      <el-form-item label="打款回单" v-if="data.procurePay.id != null && data.procurePay.reviewState == 'pass'">
        <el-upload style="width: 100%;" v-model:file-list="data.file.paymentReceipt" :action="api.baseURL() + '/backend/file/upload'" :headers="api.tokenHeader()" multiple drag :on-error="() => ElMessage.error('上传失败')" :limit="10"
          :on-preview="(f) => util.previewElfile(f)">
          <el-text>点击或拖转到此处上传文件</el-text>
        </el-upload>
      </el-form-item>
    </el-form>
  </div>
</template>

<script setup>
import { reactive, } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import api from '../../apis/api'
import cache from '../../utils/cache'
import util from '../../utils/util'

const router = useRouter()
const show = reactive({
  supplierList: [],
  paymentUsetoList: [],
})
const pageParam = cache.getObject(cache.keys.pageParam + "procurePayDetail")
const data = reactive({
  procurePay: pageParam ? JSON.parse(JSON.stringify(pageParam)) : {},
  file: {}
})

data.file.paymentReceipt = util.json2Elfile(data.procurePay.paymentReceipt)

api.get('/backend/dict/getByCode', { params: { code: 'supplier' } }).then(res => {
  show.supplierList = res.dictList
})
api.get('/backend/dict/getByCode', { params: { code: 'paymentUseto' } }).then(res => {
  show.paymentUsetoList = res.dictList
})

const add = () => {
  const param = JSON.parse(JSON.stringify(data.procurePay))
  param.applyDate = util.parseTime(param.applyDate, '{y}-{m}-{d}')
  api.post('/backend/procurePay/add', param).then(() => {
    ElMessage.success('保存成功')
    router.go(-1)
  })
}

const payment = () => {
  const param = { id: data.procurePay.id }
  param.paymentReceipt = util.elfile2Json(data.file.paymentReceipt)
  api.post('/backend/procurePay/payment', param).then(() => {
    ElMessage.success('保存成功')
    router.go(-1)
  })
}

</script>

<style lang="less">
.procurePayDetail {
  margin: auto;
  max-width: 900px;
}
</style>